const reBeNl = /^\/be-nl\//
const reBeFr = /^\/be-fr\//
const reRegistreren = /\/registreren/

export default function ({ route, i18n, redirect }) {
    if (route.fullPath.match(reBeNl)) return i18n.setLocale("nl-be")
    if (route.fullPath.match(reBeFr)) return i18n.setLocale("fr-be")

    // Redirections for deprecated public shop (replaced by shopify)
    const pathWithoutLocale = route.path.replace(`/${i18n.locale}`, "")
    if (pathWithoutLocale.startsWith("/shop")) {
        const pathWithoutShop = route.fullPath.replace("/shop", "")
        const tld = window.location.hostname.split(".").pop()
        switch (tld) {
            case "be":
            case "com":
                return redirect("https://shop.justrussel.com" + pathWithoutShop.replace("/nl-be", "").replace("/be-nl", "").replace("/fr-be", "/fr").replace("/be-fr", "/fr"))
            case "fr":
                return redirect("https://shop.justrussel.fr" + pathWithoutShop.replace("/fr", ""))
            case "nl":
                return redirect("https://shop.justrussel.nl" + pathWithoutShop.replace("/nl", ""))
            case "de":
                return redirect("https://shop.justrussel.de" + pathWithoutShop.replace("/de", ""))
            default:
                return redirect("https://shop.justrussel.com" + pathWithoutShop)
        }
    }
}
